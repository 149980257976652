body {
  background-color: white;
  color: black;
  margin: 1em;
}

th,td {
  padding-right: 1em;
}

td.numeric {
  text-align: right;
}

button + button {
  margin-left: 1em;
}

dl {
  background: #F2F2F1;
  padding: 1em;
}

dd + dt {
  margin-top: 0.5em;
}

dd {
  margin-left: 0;
  font-size: 150%;
}

table.total th {
  background: #F2F2F1;
  text-align: left;
}

table.total td {
  font-size: 150%;
}

table.total td + td {
  text-align: right;
}

table.total td.total {
  border-top: 1px solid black;
  text-align: right;
}

table.specification {
  border-collapse: collapse;
}

table.specification td, table.specification th {
  padding: 0.2em 1em;
}

table.specification th {
  text-align: left;
}

table.specification th.numeric {
  text-align: right;
}

table.specification tr.session-total th {
  border-top: 1px solid black;
  padding-bottom: 1em;
}

.tooltip {
  position: relative; /* making the .tooltip span a container for the tooltip text */
}

.tooltip:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* move to left */
  right: 100%;
  margin-right: 15px; /* and add a small margin */

  /* basic styles */
  width: 350px;
  padding: 0.3em;
  border: 1px solid black;
  border-radius: 10px;
  background: white;
  color: black;
  text-align: center;
  font-size: 1rem;

  display: none; /* hide by default */
}

.tooltip:hover:before {
  display: block;
}

#summary {
  float: left;
  width: 47.5%;
  padding-right: 2.5%;
}

#invoice {
  padding-left: 2.5%;
}

@media screen and (max-width: 750px) {
  #summary {
    float: none;
    margin-right:0;
    width:auto;
    border:0;
    padding-right: 0;
  }

  #invoice {
    padding-left: 0;
  }

  table.total {
    width: 100%;
  }
}

img.info {
  width: 0.8em;
  height: 0.8em;
}

.energy-price-tooltip {
  color: black;
  background: white;
  padding: 0.5em 1em;
}

.energy-price-tooltip ul {
  padding-left: 1em;
}
